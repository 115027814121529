import { DocumentReportIcon } from '@heroicons/vue/solid'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Contracts', 
    to: {name: 'Contracts'}, 
    icon: DocumentReportIcon, 
    position: 1,
    show: true,
    module: 'Contracts'
}