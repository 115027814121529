<template>
        <div class="pb-12 flex">
            <h1 class="font-bold text-3xl">Payments</h1>
            <button class="bg-capLightPurple hover:bg-capPurple text-white font-bold py-2 px-4 rounded-full mt-4 justify-center ml-auto" type="button" @click="generateExcel">
                Generate Excel
            </button>
        </div>  
        <div class="bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700">
            <div class="px-4 py-5 sm:p-6 font-bold">
                <div class="mb-16">
                    <div class="float-right ml-auto inline-block">
                        <label for="from" >From date:</label>
                        <input v-model="dateFrom" @change="filterByDate" class="ml-1" type="date" id="from" name="from-date">

                        <label class="pl-2" for="to">To date:</label>
                        <input v-model="dateTo" @change="filterByDate" class="ml-1" type="date" id="to" name="to-date">
                    </div>
                </div>


                <table class="w-full">
                    <tr class="flex">
                        <th v-for="(header, index) in headers" :key="index" class="flex-1 mr-auto text-left">
                            <span @click="fmSort(header.var)" class="cursor-pointer flex pl-2">
                                {{header.name}} 
                                <ArrowSmDownIcon v-show="header.var == sortField && sortOrder == 'descend'" class="h-6 w-6"/>
                                <ArrowSmUpIcon v-show="header.var == sortField && sortOrder == 'ascend'" class="h-6 w-6"/>
                                <!-- :class="{'transform rotate-180': sortDirection === 'DESC'}" 
                                class="transition-all duration-300 h-6 w-6"  -->
                            </span>
                        </th>
                    </tr>
                    <div class="border shadow-sm block h-84 overflow-y-auto">
                        <tr v-show="!isLoading" v-for="item in filteredListItems" :key="item" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 p-1 pl-2">{{item.zctContractNumberDisplay || '—'}}</td>
                            <td class="flex-1 p-1 pl-2">{{item.dateDue || '—'}}</td>
                            <td class="flex-1 p-1 pl-2">{{item.date || '—'}}</td>
                            <td class="flex-1 p-1 pl-2">{{currency(item.zcnPaymentTotal) || '-'}}</td>
                            <td class="flex-1 p-1 pl-2">{{item.paymentType || 'Credit Card'}}</td>
                            <td class="flex-1 p-1 pl-2">{{item.status || '—'}}</td>
                        </tr>
                        <tr v-show="!isLoading && filteredListItems.length < 14" v-for="index in fillRemainingRows" :key="index" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 h-7"></td>
                            <td class="flex-1"></td>
                            <td class="flex-1"></td>
                            <td class="flex-1"></td>
                            <td class="flex-1"></td>
                        </tr>
                    </div>
                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="headers.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </table>

                <div class="float-left ml-auto inline-block text-xs my-4">
                    <p>*Payments initiated prior to 2021 may be combined or have incomplete data</p>
                </div>

            </div>
        </div> 
</template>

<script>
    import SharedAPI from '@/api/SharedAPI'
    import { mapState } from 'vuex'
    import * as XLSX from 'xlsx/xlsx.mjs';
    import { saveAs } from 'file-saver'
    // import { useToast } from 'vue-toastification'

export default {
  data() {
    return {
        isLoading: true,
        payments: [],
        listItems: [],
        filteredListItems: [],
        headers: [
            {name: 'Contract Number', var: 'zctContractNumberDisplay'},
            {name: 'Date Due', var: 'dateDue'},
            {name: 'Date Paid', var: 'date'},
            {name: 'Amount', var: 'zcnPaymentTotal'},
            {name: 'Type', var: 'paymentType'},
            {name: 'Status', var: 'status'},
        ],
        exportHeaders: [
            {name: 'Contract Number', var: 'dueDate'},
            {name: 'Card Type', var: 'date'},
            {name: 'Check/Transaction #', var: 'zcnPaymentTotal'},
            {name: 'Date Paid', var: 'paymentType'},
            {name: 'Amount Paid', var: 'status'},
            {name: 'Status', var: 'status'},
            {name: 'Payment Type', var: 'status'},
            {name: 'Date Due', var: 'status'},
        ],
        dateTo: '',
        dateFrom: '',
        sortKey: 'zctContractNumberDisplay',
        sortDirection: 0,
        sortField: 'zctContractNumberDisplay',
        sortOrder: 'ascend'
    };
  },

  mounted() {
    this.getPayments()
  },

  computed: {
    ...mapState([
      'user'
      ]),

    fillRemainingRows(){
        var fillRows = 14 - this.listItems.length 
        if (fillRows > 0){
            return fillRows
        }else{
            return 0
        }
    }
  },

  methods: {
    getPayments: async function(){
        try{
            this.isLoading = true
            this.payments = []
            this.listItems = []
            this.filteredListItems = []

            var params = [{
                // '_kftContactID' : this.user.kptID,
                'payment__CONTRACT__contractID::_kftContactID' : this.user.kptID,
                '_kftContractID' : '*',
                '_kftClaimID' : '='
            }]
            var sort = [{
                'fieldName': this.sortField,
                'sortOrder': this.sortOrder,
            }]
            var res = await SharedAPI.performFind(params, 'payment', sort)      
            res.response.data.forEach(payment => {
                this.payments.push(payment.fieldData)
            })
            this.listItems = this.payments
            this.filteredListItems = this.payments

        }catch(e){
            console.log(e)
        }

        this.isLoading = false     
    },

    generateExcel: async function(){

        var wb = XLSX.utils.book_new()
        wb.Props = {
            Title: "Payments",
            Subject: "Payments",
            Author: "Web",
            CreatedDate: new Date()
        }

        wb.SheetNames.push("Payments")
 
        var data = []

        var headersArray = []
        for (var i in this.exportHeaders){
            headersArray.push(this.exportHeaders[i].name)
        }
        data.push(headersArray)

        var exportArray = []
        this.filteredListItems.forEach ( item => {
            exportArray.push({
                contractNumber: item.zcnContractNumber,
                cardType: item.ccType,
                checkTransactionNo: item.checkOrTransNo,
                datePaid: item.date,
                amountPaid: item.zcnPaymentTotal,
                status: item.status,
                paymentType: item.paymentType,
                dueDate: item.dateDue
            })
        })
        console.log(exportArray)

        for (i in exportArray){
            var dataArray = []
            for (var property in exportArray[i]){
                dataArray.push(exportArray[i][property])
            }

            data.push(dataArray)
        }

        var ws = XLSX.utils.aoa_to_sheet(data)
        wb.Sheets["Payments"] = ws

        var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'})

        saveAs(new Blob([this.s2ab(wbout)], {type:"application/octet-stream"}), 'Payments.xlsx')
    },

    s2ab: function(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    },

    // startSort: function(key){
    //     if (this.sortKey == key){
    //         if (this.sortDirection == 0){
    //             key = '-' + key
    //             this.sortDirection = 1
    //         }else{
    //             this.sortDirection = 0
    //         }
    //     }else{
    //         this.sortKey = key
    //         this.sortDirection = 0
    //     }
    //     this.filteredListItems = this.filteredListItems.sort(this.dynamicSort(key))
    // },

    // dynamicSort: function(property) {
        
    //     var sortOrder = 1;
    //     if(property[0] === "-") {
    //         sortOrder = -1;
    //         property = property.substr(1);
    //     }
    //     return function (a,b) {
    //         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //         return result * sortOrder;
    //     }
    // },

    fmSort: function(field){
        this.sortField = field
        var sortOrder = this.sortOrder == 'descend' ? "ascend" : "descend";
        this.sortOrder = sortOrder
        this.getPayments()
    },

    filterByDate: function() {
        if (this.dateTo == '' && this.dateFrom == ''){
            this.filteredListItems = this.listItems
            return
        }

        if (this.dateFrom != '' && this.dateTo != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.date) >= Date.parse(this.dateFrom) && Date.parse(item.date) <= (Date.parse(this.dateTo) + (60*60*24*1000)))
        }else if (this.dateFrom != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.date) >= Date.parse(this.dateFrom))
        }else if (this.dateTo != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.date) <= (Date.parse(this.dateTo) + (60*60*24*1000)))
        }
    },

    currency: function(price){
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price)
    }

  }
}

</script>
