import { HomeIcon } from '@heroicons/vue/outline'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'My Profile', 
    to: {name: 'My Profile'}, 
    icon: HomeIcon, 
    position: 0,
    show: false,
    module: 'profile'
}