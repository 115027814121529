import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            isPasswordChangeNeeded: false,
            kptID: "",
            companyName: "",
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            customerID: "",
        },
        onPasswordReset: false,
        onPasswordChange: false,
        currentPassword: "",
        mobileDevice: false,
        invoices: [],
        payments: [],
        licenses: [],
        currentPage: "",
        currentPageParams: "",
    },
    getters: {
        isLoggedIn: (state) => {
          return state.user.loggedIn;
        },
        isPasswordChangeNeeded: (state) =>{
          return state.user.isPasswordChangeNeeded;
        }
      },
    mutations: {
        setCurrentPage(state, payload) {
          state.currentPage = payload.page;
          state.currentPageParams = payload.params;
        },
        setUserData(state, payload) {
          state.user = payload;
          // state.user.loggedIn = true;
        },
        setCurrentPassword(state, payload) {
          state.currentPassword = payload;
        },
        setMobileDevice(state, payload) {
          state.mobileDevice = payload;
        },
        setPasswordChangeNeeded(state, payload) {
          state.user.isPasswordChangeNeeded = payload;
        },
        setOnPasswordReset(state, payload){
          state.onPasswordReset = payload;
        },
        setOnPasswordChange(state, payload){
          state.onPasswordChange = payload;
        },
        setSquareCustomerID(state, payload){
          state.user.customerID = payload;
        },
        // setPasswordRequested(state, payload) {
        //   state.user.requestedPassword = payload;
        // },
        // setUserIDAndName(state, payload) {
        //   state.user.id = payload.id
        //   state.user.companyID = payload.companyID
        //   state.user.firstName = payload.firstName
        // },
        setInvoicesPaymentsLicenses(state, payload) {
          state.invoices = payload.invoices;
          state.payments = payload.payments;
          state.licenses = payload.licenses;
        },
        logout: (state) => {
          state.user = {
            loggedIn: false,
            isPasswordChangeNeeded: false,
            kptID: "",
            companyName: "",
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            customerID: "",
          }
          state.onPasswordReset = false,
          state.onPasswordChange = false,
          state.currentPassword = ""
          state.mobileDevice = false
          state.invoices = []
          state.payments = []
          state.licenses = []
          state.currentPage = ""
          state.currentPageParams = ""
        },
    },
    plugins: [createPersistedState()],
})