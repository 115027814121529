import Claims from './Claims.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/claims',
      name: 'Claims',
      component: Claims,
      meta: { module: 'Claims' }
    }
];