<template>
   <div class="h-screen flex overflow-hidden bg-gray-100">
    <!-- <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        
        <div class="px-4 py-4 sm:px-0">
          <slot></slot>
        </div>
        
      </div>
    </main> -->
    <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="h-full">
          <!-- <div class="max-w-7xl px-4 sm:px-6">
            <h1 class="text-2xl font-semibold text-gray-900">{{$route.name}}</h1>
          </div> -->
          <div class="h-full">
            <!-- Replace with your content -->
            <div class="h-full">
                <slot></slot>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'UpdatePaymentLayout',
  setup() {

    console.log('on update payment layout')

  },
})
</script>