<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-db-background bg-cover">
    <div class="xl:absolute xl:right-0 md:w-3/5 m-auto xl:w-2/5">
      <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10 md:mx-auto lg:w-3/4 w-full">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto w-2/5 xl:h-full xl:w-full" src="@/assets/cap-logo.png" alt="Complete Appliance Protection" />
        </div>
        
          <h2 class=" my-4 text-xl font-extrabold text-gray-900 text-left xl:text-2xl">Reset Password</h2>
          <div class="mt-4">
            <label for="username" class="block text-sm font-medium text-gray-700 xl:text-base">
              Email
            </label>
            <div class="mt-1">
              <input v-model="email" placeholder="Email" id="username" name="username" type="username" autocomplete="username" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-md placeholder-gray-400 focus:outline-none focus:ring-capLightPurple focus:border-capLightPurple sm:text-sm" :class="( emailFilled ? 'border-gray-300' : 'border-red-500' )"/>
            </div>
          </div>
          
          <div>
            <button @click="passwordReset" type="submit" class="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-capPurple hover:bg-capLightPurple focus:outline-none">
              Submit
            </button>
            <button @click="cancel" type="submit" class="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-gray-400 hover:bg-gray-600 focus:outline-none">
              Cancel
            </button>
          </div>
      </div>
    </div>
  </div>
  <!-- <div v-if="this.loading">
    <link rel="stylesheet" href="https://pagecdn.io/lib/font-awesome/5.10.0-11/css/all.min.css" integrity="sha256-p9TTWD+813MlLaxMXMbTA7wN/ArzGyW/L7c5+KkjOkM=" crossorigin="anonymous">
    <div class="w-full h-full fixed block top-0 left-0 z-50 bg-white opacity-80">
      <span class="text-indigo-900 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 45%">
        <i class="fas fa-circle-notch fa-spin fa-5x"></i>
      </span>
    </div>
  </div> -->

</template>

<script setup>

// #region import statements
  import { ref } from 'vue'
  // import { useStore } from 'vuex'
  import {router} from '@/router/index.ts'
  import SharedAPI from '@/api/SharedAPI'
  import { useToast } from 'vue-toastification'
// #endregion

// #region setup
  const email = ref('')

  const emailFilled = ref(true)

  // const store = useStore()

  var toast = useToast()
// #endregion

// #region login function
  async function passwordReset() {
    if (email.value == ''){
      emailFilled.value = false
      return
    }

    try{
      const params = {
          username: email.value
      }
      const res = await SharedAPI.runScript('Contact - Reset User Password', JSON.stringify(params))
      // console.log(res)

      if (JSON.parse(res.response.scriptResult).messages.code != '0'){
        showNoti(JSON.parse(res.response.scriptResult).messages.message, 'err')
        return
      }
      else{
        showNoti("Password has successfully been reset. Please check your email for a temporary password.", 'success')
      }

      // store.commit('setOnPasswordReset', false)
      router.push({name: 'Login'})
    }catch(e){
      console.log(e)
    }
  }
// #endregion

// #region cancel password reset and return to login screen
  function cancel() {
    // store.commit('setOnPasswordReset', false)
    router.push({name: 'Login'})
  }
// #endregion

// #region Show notification
function showNoti(errMsg, type){
  if (type == 'err'){
    toast.error(errMsg, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
    })
  } else if (type == 'success'){
    toast.success(errMsg, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
    })
  }
}
// #endregion

</script>

