import axios from 'axios'
import { storeKey, useStore } from 'vuex';
const md5 = require('md5');

export default {
    login: function(username: string, password: string, cb: any){
        authenticate(username, password, cb)
    },
    // getUserID: function(username: string, cb: any){
    //     getUserID(username, cb)
    // },
    
}

async function authenticate(username: string, password: string, cb: any) {
    const store = useStore()
    const data = {
        username: username,
        password: password,
        // id: store.state.user.kptID
    }
    
    axios.post('login.php', JSON.stringify(data))
    .then(response => {
        console.log(response)
        if (response.data.loggedIn == true){
            cb({
                authenticated: true,
                user: response.data
            })
        }else{
            cb({
                authenticated: false,
                errorMsg: response.data.message
            })
        }    
    }, error => {
        console.log(error)
        cb({
            authenticated: false,
            errorMsg: error,
            error: error
        })
    })
}

// async function getUserID(username: string, cb: any) {
    
//     const data = {
//         action: "getID",
//         payload: {
//             username: username,
//         },
//     }

//     axios.post('/', JSON.stringify(data))
//     .then(response => {
//         const scriptResult = JSON.parse(response.data.response.scriptResult);
//         const code = scriptResult.messages.code ;
//         const message = scriptResult.messages.message ;
//         const result = scriptResult.result.data;
//         const id = result.id ;

//         // console.log(scriptResult)
//         if(code === 1 || code === 2) {
//             const res = {
//                 passwordResetSuccessful: false,
//                 message: message
//             }
//             cb(res)
//             return res
//         } else {
//             const res = {
//                 passwordResetSuccessful: true,
//                 data: {
//                     id: id
//                 }
//             }
//             cb(res)
//             return response
//         }
//     })
//     .catch(error => {
//         console.log(error)
//         const res = {
//             passwordResetSuccessful: false,
//             message: "An error occurred."
//         }
//         cb(res)
//         return res
//     }) 
// }
