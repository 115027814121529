<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-capPurple">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex items-center flex-shrink-0 px-4">
              <span class="text-gray-100 text-md font-bold">Hello</span>
            </div>
            <div class="flex items-center flex-shrink-0 px-4 mb-5">
              <span class="text-gray-100 text-2xl pl-5 font-extrabold">{{store.state.user.firstName}}</span>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link v-for="item in navigation" :key="item.name" :to="item.to" :class="[item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-capLightPurple ', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-capPurple md:flex md:flex-shrink-0">
      <div class="flex flex-col w-48">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <span class="text-gray-100 text-lg font-bold">Hello</span>
          </div>
          <div class="flex items-center flex-shrink-0 px-4 mb-5">
            <span class="text-gray-100 text-3xl pl-5 font-extrabold">{{store.state.user.firstName}}</span>
          </div>
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link v-for="item in navigation" :key="item.name" :to="item.to" :class="[$route.name == item.name ? 'bg-gray-100 bg-opacity-25 text-white' : 'text-white hover:bg-capLightPurple', 'group flex items-center px-2 py-2 text-lg font-bold']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-8 w-8 text-white" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-20 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        
        
        <div class="flex-1 px-4 flex justify-between z-0">
          <div class="flex-1 flex">
            <div class="h-full flex my-auto md:px-4">
              <a class="my-auto" href="https://www.completehomewarranty.com/">
                <img class="h-11 w-auto left-0 my-auto" src="@/assets/cap-logo.png" alt="Complete Appliance Protection" />
              </a>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6 space-x-2">
            <p class="cursor-pointer hover:text-capPurple " @click="this.goToProfile()">My Profile</p>
            <p>|</p>
            <p class="cursor-pointer hover:text-capPurple" @click="this.logout()">Log Out</p>
            <!-- Profile dropdown -->
            <!-- <Menu as="div" class="ml-3 relative right-0">
              <div>
                <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu> -->
          </div>
        </div>
      </div>
      <div class="relative z-0 md:h-1 bg-capPurple"></div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6 h-full">
          <!-- <div class="max-w-7xl px-4 sm:px-6">
            <h1 class="text-2xl font-semibold text-gray-900">{{$route.name}}</h1>
          </div> -->
          <div class="px-4 sm:px-6 h-full">
            <!-- Replace with your content -->
            <div class="py-4 h-full">
                <slot></slot>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/vue/outline'

import { SearchIcon } from '@heroicons/vue/solid'
import { useStore } from 'vuex'
import {router} from '@/router/index.ts'

const userNavigation = [
  { name: 'My Profile', href: 'localhost:8080/MyProfile.vue' },
  { name: 'Sign out', href: '#' },
]

export default defineComponent({
  name: 'ApplicationShellVerticalExample',
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
  },
  setup() {
    const store = useStore()
    const sidebarOpen = ref(false)
    const currentPage = store.state.currentPage
    const currentPageParams = store.state.currentPageParams

    //Load file path of all modules named Nav.vue
    const context = require.context('@/modules', true, /Nav.ts$/i)

    //Import each component
    const modules = context.keys()
        .map(context)
        .map(m => m.nav) 

    //Sort them based on the position data prop

    // Filtering Licensing for Sales Force customers.
    const navigation = modules.filter(m =>{
      if(store.state.user.loggedIn == "false") {
        return m.name != "Licensing" && m.show
      } else {
        return m.show
      }
    }).sort((a,b)=>{
        let aPos = a.position
        let bPos = b.position
        if(aPos < bPos){
            return -1
        }else if(aPos > bPos){
            return 1
        }else{
            return 0
        }
    })

    return {
      navigation,
      userNavigation,
      sidebarOpen,
      store,
      currentPage,
      currentPageParams
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({name: "My Profile"})
    },
    logout() {
      this.store.commit('logout');
      this.$router.push({ name: 'Login'})
    }     
  },
  mounted() {
    if(this.currentPage == "") {
      router.push({name: "Contracts"})
    } else {
      router.push({name: this.currentPage, params: this.currentPageParams})
    }
  }
})
</script>