import Contracts from './Contracts.vue'
import ContractDetail from './ContractDetail.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/contracts',
      name: 'Contracts',
      component: Contracts,
      meta: { module: 'Contracts' }
    },
    {
      path: '/contracts/contract-detail/:id',
      name: 'ContractDetail',
      component: ContractDetail,
      meta: { module: 'Contracts' },
      props: true
    }
];