import PasswordReset from './PasswordReset.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement
import AuthLayout from '@/layouts/AuthLayout.vue'

export const route: Route[] = [
    {
      path: '/password-reset',
      name: 'PasswordReset',
      component: PasswordReset,
      meta: { layout: AuthLayout }
    },
];