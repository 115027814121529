import { ClipboardListIcon } from '@heroicons/vue/solid'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Claims', 
    to: {name: 'Claims'}, 
    icon: ClipboardListIcon, 
    position: 3,
    show: true,
    module: 'Claims'
}