<template>
    <div class="pb-12 flex">
        <h1 class="font-bold text-3xl">My Profile</h1>
        <button class="bg-capLightPurple hover:bg-capPurple text-white font-bold py-2 px-4 rounded-full mt-4 justify-center ml-auto" type="button">
            Update Payment Method (Coming Soon)
        </button>
    </div>
    <div class="flex flex-row space-x-6">
        <div class="flex-1 bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700">
            <div v-if="!isLoading" class="px-4 py-5 sm:p-6 font-bold">
                <h1 class="text-gray-600 pb-4 text-xl">Contact Information</h1>

                <h2 class="text-gray-500 pt-4">Name</h2>
                <h2 v-if="profileInfo.zctFullName != ''">{{ profileInfo.zctFullName }}</h2>
                <h2 v-else>—</h2>

                <h2 class="text-gray-500  pt-4">Email</h2>
                <h2 v-if="profileInfo.zctPrimaryEmail != ''">{{ profileInfo.zctPrimaryEmail }}</h2>
                <h2 v-else>—</h2>

                <h2 class="text-gray-500  pt-4">Phone #</h2>
                <h2 v-if="profileInfo.zctWebPhone != ''">{{ profileInfo.zctWebPhone }}</h2>
                <h2 v-else>—</h2>

                <h2 class="text-gray-500  pt-4">Billing Address</h2>
                <div v-if="profileInfo.zctWebAddressBillingLine1 != ''">
                    <h2>{{ profileInfo.zctWebAddressBillingLine1 }}</h2>
                    <h2>{{ profileInfo.zctWebAddressBillingLine2 }}</h2>
                    <h2>{{ profileInfo.zctWebAddressBillingCity }}, {{ profileInfo.zctWebAddressBillingState }} {{ profileInfo.zctWebAddressBillingZip }}</h2>
                    <h2>{{ profileInfo.zctWebAddressBillingCountry }}</h2>
                </div>
                <div v-else>
                    <h2>—</h2>
                </div>

                <h2 class="text-gray-500  pt-4">Mailing Address</h2>
                <div v-if="profileInfo.zctWebAddressMailingLine1 != ''">    
                    <h2>{{ profileInfo.zctWebAddressMailingLine1 }}</h2>
                    <h2>{{ profileInfo.zctWebAddressMailingLine2 }}</h2>
                    <h2>{{ profileInfo.zctWebAddressMailingCity }}, {{ profileInfo.zctWebAddressMailingState }} {{ profileInfo.zctWebAddressMailingZip }}</h2>
                    <h2>{{ profileInfo.zctWebAddressMailingCountry }}</h2>
                </div>
                <div v-else>
                    <h2>—</h2>
                </div>

                <!-- <h2 class="text-gray-500  pt-4">Default Payment Type</h2>
                <h2 v-if="profileInfo.zctWebPaymentMethodType != ''">{{ profileInfo.zctWebPaymentMethodType }}</h2>
                <h2 v-else>—</h2>
                
                <h2 class="text-gray-500  pt-4">Last 4 Digits</h2>
                <h2 v-if="profileInfo.zctWebPaymentMethodLast4 != ''">{{ profileInfo.zctWebPaymentMethodLast4 }}</h2>
                <h2 v-else>—</h2> -->

            </div>
            <div v-if="isLoading" class="text-center w-full p-4">
                <tw-loading></tw-loading>
            </div>
        </div> 

        <div class="flex-1 bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700 mb-8">
            <div v-if="!isLoading" class="px-4 py-5 sm:p-6 font-bold">
                <div class="mb-6">
                    <h2 class="font-bold text-lg text-gray-700">Contact Methods</h2>
                </div>
                <table class="w-full">
                    <tr class="flex">
                        <th v-for="(header, index) in headers" :key="index" class="mr-auto text-left" :class="index == 0? 'w-32' : 'flex-1'">
                            <span @click="startSort(header.var)" class="cursor-pointer flex pl-2">
                                {{header.name}} 
                                <ArrowSmDownIcon v-show="header.var == sortKey && sortDirection == 1" class="h-6 w-6 pl-2"/>
                                <ArrowSmUpIcon v-show="header.var == sortKey && sortDirection == 0" class="h-6 w-6 pl-2"/>
                            </span>
                        </th>
                    </tr>
                    <div class="border shadow-sm block h-84 overflow-y-auto">
                        <tr v-show="!isLoading" v-for="item in filteredListItems" :key="item" class="flex flex-row even:bg-purple-100">
                            <td class="flex-initial w-32 p-1  pl-2">{{item.primaryFlag == 1 ? 'Yes' : ''}}</td>                            
                            <td class="flex-1 p-1  pl-2">{{item.type || '—'}}</td>
                            <td class="flex-1 p-1">{{item.contact || '—'}}</td>
                        </tr>
                        <tr v-show="!isLoading && listItems.length < 14" v-for="index in fillRemainingRows" :key="index" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 h-7 pl-2"></td>
                            <td class="flex-1 pl-2"></td>
                        </tr>
                    </div>
                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="headers.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="isLoading" class="text-center w-full p-4">
                <tw-loading></tw-loading>
            </div>
        </div> 
    </div>
</template>

<script>
    import SharedAPI from '@/api/SharedAPI'
    import { mapState } from 'vuex'

export default {
  data() {
    return {
        isLoading: true,
        profileInfo:{},
        contactMethods: [],
        listItems: [],
        filteredListItems: [],
        headers: [
            {name: 'Primary', var: 'primaryFlag'},
            {name: 'Type', var: 'type'},
            {name: 'Contact Method', var: 'contact'},

        ],
        sortKey: 'primaryFlag',
        sortDirection: 0
    };
  },

  mounted() {
    this.getProfileInfo()
  },

  computed: {
    ...mapState([
      'user'
      ]),

    fillRemainingRows(){
        var fillRows = 14 - this.listItems.length 
        if (fillRows > 0){
            return fillRows
        }else{
            return 0
        }
    }
  },

  methods: {
    getProfileInfo: async function(){
        try{
            //Get User Info
            var params = [{
                '__kptID' : this.user.kptID,
            }]
            var res = await SharedAPI.performFind(params, 'contact') 
            console.log(res)           
            this.profileInfo = res.response.data[0].fieldData

            //Get Contact Methods
            var paramsContactMethod = [{
                '_kftContactID' : this.user.kptID,
            }]
            var resContactMethod = await SharedAPI.performFind(paramsContactMethod, 'contact_method') 
            console.log(resContactMethod)           
            resContactMethod.response.data.forEach(contactMethod => {
                this.contactMethods.push(contactMethod.fieldData)
            })
            this.listItems = this.contactMethods
            this.filteredListItems = this.contactMethods

        }catch(e){
            console.log(e)
        }

        this.isLoading = false     
    },
    startSort: function(key){
        if (this.sortKey == key){
            if (this.sortDirection == 0){
                key = '-' + key
                this.sortDirection = 1
            }else{
                this.sortDirection = 0
            }
        }else{
            this.sortKey = key
            this.sortDirection = 0
        }
        this.filteredListItems = this.filteredListItems.sort(this.dynamicSort(key))
    },

    dynamicSort: function(property) {
        
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },

    currency: function(price){
        return "$".concat(Number(price).toFixed(2));
    }
  }
}

</script>