<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-db-background bg-cover">
    <div class="xl:absolute xl:right-0 md:w-3/5 m-auto xl:w-2/5">
      <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10 md:mx-auto lg:w-3/4 w-full">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto w-2/5 xl:h-full xl:w-full" src="@/assets/cap-logo.png" alt="Complete Appliance Protection" />
        </div>
          <h2 class=" my-4 text-xl font-extrabold text-gray-900 text-left xl:text-2xl">Customer Login</h2>
        <div v-if="!isLoading" class=" h-56 ">
          <div class="mt-4">
            <label for="username" class="block text-sm font-medium text-gray-700 xl:text-base">
              Email
            </label>
            <div class="mt-1">
              <input v-model="username" placeholder="Email" id="username" name="username" type="username" autocomplete="username" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-md placeholder-gray-400 focus:outline-none focus:ring-capLightPurple focus:border-capLightPurple sm:text-sm" :class="( usernameFilled ? 'border-gray-300' : 'border-red-500' )"/>
            </div>
          </div>
          <div class="mt-4">
            <label for="password" class="block text-sm font-medium text-gray-700 xl:text-base">
              Password
            </label>
            <div class="mt-1">
              <input v-model="password" placeholder="Password" id="password" name="password" @keyup.enter="login" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-md placeholder-gray-400 focus:outline-none focus:ring-capLightPurple focus:border-capLightPurple sm:text-sm" :class="( passwordFilled ? 'border-gray-300' : 'border-red-500' )"/>
            </div>
          </div>
          <div>
            <button @click="login" type="submit" class="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-capPurple hover:bg-capLightPurple focus:outline-none">
              Sign in
            </button>
          </div>
          <div class="flex mt-4 items-center justify-between">
            <div class="text-m=xs">
              <p @click="passwordReset" class="text-xs text-capLightPurple hover:text-capPurple 2xl:text-base cursor-pointer">Forgot your password?</p>
            </div>
            <div class="flex items-center">
              <!-- <label for="remember-me" class="ml-2 block text-sm text-gray-900 2xl:text-base">
                Need an account?
              </label> -->
              <!-- <div class="text-xs">
                <a href="#" class="text-xs text-capLightPurple hover:text-capPurple ml-1 2xl:text-base cursor-pointer" @click="openModal = true">Need an account?</a>
              </div> -->
            </div>
          </div>
          </div>
          <div v-else class="h-52 text-center"><p class=" italic mt-8">Logging in...</p><tw-loading class="-mt-16"/></div>
      </div>
    </div>
  </div>
</template>

<script setup>

// #region import statements
  import loginAPI from './loginAPI.ts'
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import {router} from '@/router/index.ts'
  import { useToast } from 'vue-toastification'
// #endregion

// #region setup
  const username = ref('')
  const password = ref('')

  const usernameFilled = ref(true)
  const passwordFilled = ref(true)

  const store = useStore()
  const isError = ref(false)
  const message = ref('')
  const isLoading = ref(false)

  var toast = useToast()
// #endregion

// #region login function
  async function login() {
      if (username.value == ""){
        usernameFilled.value = false
      }else{
        usernameFilled.value = true
      }

      if (password.value == ""){
        passwordFilled.value = false
      }else{
        passwordFilled.value = true
      }

      if (!usernameFilled.value || !passwordFilled.value){
        return
      }
      isLoading.value = true
      await loginAPI.login(username.value, password.value, response => {
        //console.log(response)
        if(response.authenticated === true) {
          store.commit('setUserData', response.user)

          // console.log(response.user.isPasswordChangeNeeded)
          console.log(response.user)
          if (response.user.isPasswordChangeNeeded == 1){
            store.commit('setOnPasswordChange', true)
            router.push({ name: 'PasswordChange' })
          }else{
            router.push({ name: 'Contracts' })
          }
        } else {
          isError.value = true
          message.value = response.errorMsg
          // console.log(message.value)
          showNoti(message.value)
          // console.log(response)
          isLoading.value = false
        }
      })
  }
// #endregion

// #region route to password reset page
function passwordReset() {
  // store.commit('setOnPasswordReset', true)
  router.push({name: "PasswordReset"})
}

// #region Show notification
function showNoti(errMsg){
    toast.error(errMsg, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
    })
}
// #endregion
</script>

