<template>
        <div class="pb-12 flex">
            <h1 class="font-bold text-3xl">Contracts</h1>
        </div> 

        <div class="bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700">
            <div class="px-4 py-5 sm:p-6 font-bold">
                <!-- <div class="mb-16">
                    <h1 class="font-extrabold text-xl float-left inline-block">Contracts</h1>
                </div> -->
                <table class="w-full">
                    <tr class="flex">
                        <th v-for="(header, index) in headers" :key="index" class="flex-1 mr-auto text-left">
                            <span @click="startSort(header.var)" class="cursor-pointer flex pl-2">
                                {{header.name}} 
                                <ArrowSmDownIcon v-show="header.var == sortKey && sortDirection == 1" class="h-6 w-6"/>
                                <ArrowSmUpIcon v-show="header.var == sortKey && sortDirection == 0" class="h-6 w-6"/>
                            </span>
                        </th>
                    </tr>
                    <div class="border shadow-sm block h-84 overflow-y-auto">
                        <tr v-show="!isLoading" v-for="item in filteredListItems" :key="item" @click="routeToDetailPage(item.__kptID)" class="flex flex-row even:bg-purple-100 hover:bg-purple-50 cursor-pointer">
                            <td class="flex-1 text-left mr-auto pl-2">{{item.contractNumberDisplay || '—'}}</td>
                            <td class="flex-1 text-left mr-auto pl-2">{{item.zctWebAddrLine1 || '—'}}</td>
                            <td class="flex-1 text-left mr-auto pl-2">{{item.planName || '—'}}</td>
                            <td class="flex-1 text-left mr-auto pl-2">{{currency(item.zcnGrandTotal)|| '—'}}</td>
                            <td class="flex-1 text-left mr-auto pl-2">{{item.paymentFrequencyDisplayWeb|| '—'}}</td>
                            <td class="flex-1 text-left mr-auto">{{item.status || '—'}}</td>
                        </tr>
                        <tr v-show="!isLoading && listItems.length < 14" v-for="index in fillRemainingRows" :key="index" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 h-7"></td>
                            <td class="flex-1"></td>
                            <td class="flex-1"></td>
                            <td class="flex-1"></td>
                        </tr>
                    </div>
                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="headers.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </table>
            </div>
        </div> 
</template>


<script>
    import SharedAPI from '@/api/SharedAPI'
    import { mapState } from 'vuex'
    import {router} from '@/router/index.ts'
    // import { useToast } from 'vue-toastification'
    

export default {
  data() {
    return {
        isLoading: true,
        contracts: [],
        listItems: [],
        filteredListItems: [],
        headers: [
            {name: 'Contract Number', var: 'contractNumberDisplay'},
            {name: 'Address', var: 'zctWebAddrLine1'},
            {name: 'Coverage', var: 'planName'},
            {name: 'Plan Cost', var: 'zcnGrandTotal'},
            {name: 'Bill Frequency', var: 'paymentFrequencyDisplayWeb'},
            {name: 'Status', var: 'status'},
        ],
        sortKey: 'zctWebAddrLine1',
        sortDirection: 0
    };
  },

  mounted() {
    this.getContracts()
  },

  computed: {
    ...mapState([
      'user'
      ]),

    fillRemainingRows(){
        var fillRows = 14 - this.listItems.length 
        if (fillRows > 0){
            return fillRows
        }else{
            return 0
        }
    }
  },

  methods: {
    getContracts: async function(){
        try{
            var params = [{
                '_kftContactID' : this.user.kptID,
            },
            {
                'status': 'Deleted',
                'omit': 'true'
            }
            
            ]
            var res = await SharedAPI.performFind(params, 'contract') 
            console.log(res)           
            res.response.data.forEach(contract => {
                this.contracts.push(contract.fieldData)
            })
            this.listItems = this.contracts
            this.filteredListItems = this.contracts

        }catch(e){
            console.log(e)
        }

        this.isLoading = false     
    },

    routeToDetailPage: function(id){
        router.push({name: "ContractDetail", params:{id: id}})
    },

    startSort: function(key){
        if (this.sortKey == key){
            if (this.sortDirection == 0){
                key = '-' + key
                this.sortDirection = 1
            }else{
                this.sortDirection = 0
            }
        }else{
            this.sortKey = key
            this.sortDirection = 0
        }
        this.filteredListItems = this.filteredListItems.sort(this.dynamicSort(key))
    },

    dynamicSort: function(property) {
        
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },

    currency: function(price){
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price)
    }
  }
}
</script>

<style scoped>
    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 98;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;

        width: 100%;
        max-width: 400px;
        background-color: #FFF;
        border-radius: 16px;

        padding: 25px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-enter-active, 
    .slide-leave-active {
        transition: transform 0.5s;
    }

    .slide-enter-from, 
    .slide-leave-to {
        transform: translateY(-50%) translateX(100vw);
    }
</style>
