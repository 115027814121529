<template>
        <div class="pb-12">
            <h1 class="font-bold text-3xl">Claims</h1>
        </div>  
        <div class="bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700">
            <div class="px-4 py-5 sm:p-6 font-bold">
            <!-- Content goes here -->
                <div class="mb-16">
                    <div class="float-right ml-auto inline-block mb-4">
                       

                        <label for="from" >From date:</label>
                        <input v-model="dateFrom" @change="filterByDate" class="ml-1" type="date" id="from" name="from-date">

                        <label class="pl-2" for="to">To date:</label>
                        <input v-model="dateTo" @change="filterByDate" class="ml-1" type="date" id="to" name="to-date">
                    </div>
                    <!-- <h1 class="font-extrabold text-xl float-left inline-block">Claims</h1> -->
                </div>

                <table class="w-full">
                    <tr class="flex">
                        <th v-for="(header, index) in headers" :key="index" class="flex-1 mr-auto text-left">
                            <span @click="fmSort(header.var)" class="cursor-pointer flex">
                                {{header.name}} 
                                <ArrowSmDownIcon v-show="header.var == sortField && sortOrder == 'descend'" class="h-6 w-6"/>
                                <ArrowSmUpIcon v-show="header.var == sortField && sortOrder == 'ascend'" class="h-6 w-6"/>
                                <!-- :class="{'transform rotate-180': sortDirection === 'DESC'}" 
                                class="transition-all duration-300 h-6 w-6"  -->
                            </span>
                        </th>
                    </tr>
                    <div class="border shadow-sm block h-84 overflow-y-auto">
                        <tr v-show="!isLoading" v-for="item in filteredListItems" :key="item" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 p-1 mx-auto">{{item.zctWebAddressLine1 || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">{{item.claimNumber || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">{{item.claimDate || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">{{currency(item.zcnLineItemTotal) || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">{{item.status || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">{{item.item || '—'}}</td>
                            <td class="flex-1 p-1 mx-auto">
                                <span class="tooltip">More...
                                    <span class="tooltiptext">
                                        <ul>
                                            <li>Contract Number: {{item.contractNumberDisplay || '-'}}</li>
                                            <li>Authorization Number: {{item.authorizationNumber || '-'}}</li>
                                            <li>Date Paid: {{item.zctWebDatePaid || '-'}}</li>
                                            <li>Payee: {{item.zctPayeeName || '-'}}</li>
                                            <li>Pay Status: {{item.zctWebPaymentStatus || '-'}}</li>
                                            <li>Amount Paid: {{currency(item.zcnWebAmountPaid) || '-'}}</li>
                                        </ul>
                                    </span>
                                </span>
                            </td>

                        </tr>
                        <div v-if="!isLoading && listItems.length < 14">
                            <tr v-for="index in fillRemainingRows" :key="index" class="flex flex-row even:bg-purple-100">
                                <td class="flex-1 h-7"></td>
                                <td class="flex-1"></td>
                                <td class="flex-1"></td>
                                <td class="flex-1"></td>
                                <td class="flex-1"></td>
                            </tr>
                        </div>
                    </div>
                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="headers.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </table>

                <div class="float-left ml-auto inline-block text-xs my-4">
                        <p>*Claims initiated prior to 2021 may be combined or have incomplete data</p>
                        <p>**If there is more than one payment associated with a single claim, the information in the popover will reflect that of the most recent payment.</p>
                </div>
            </div>
        </div> 
</template>

<script>

    // #region import statements
    import SharedAPI from '@/api/SharedAPI'
    import { mapState } from 'vuex'
    // #endregion

    export default {
        data() {
            return {
                isLoading: true,
                claims: [],
                listItems: [],
                filteredListItems: [],
                headers: [
                    {name: 'Address', var: 'zctWebAddressLine1'},
                    {name: 'Claim Number', var: 'claimNumber'},
                    {name: 'Claim Date', var: 'claimDate'},
                    {name: 'Cost', var: 'zcnLineItemTotal'},
                    {name: 'Status', var: 'status'},
                    {name: 'Item', var: 'item'},
                    {name: '', var: ''}
                ],
                sortKey: 'zctWebAddressLine1',
                sortDirection: 0,
                dateTo: '',
                dateFrom: '',
                sortField: 'zctWebAddressLine1',
                sortOrder: 'ascend'
            };
        },

  mounted() {
    this.getClaims()
  },

  computed: {
    ...mapState([
      'user'
      ]),

    fillRemainingRows(){
        var fillRows = 14 - this.listItems.length 
        if (fillRows > 0){
            return fillRows
        }else{
            return 0
        }
    }
  },

  methods: {
    getClaims: async function(){
        try{
            this.isLoading = true
            this.claims =[]
            this.listItems = []
            this.filteredListItems =[]

            var params =[{
                    'isActive' : 1,
                    // '_kftContactID' : this.user.kptID,
                    'claim__CONTRACT__contractID::_kftContactID' : this.user.kptID
            }]
            var sort = [{
                'fieldName': this.sortField,
                'sortOrder': this.sortOrder,
            }]

            var res = await SharedAPI.performFind(params, 'claim', sort) 
            console.log(res)           
            res.response.data.forEach(claim => {
                this.claims.push(claim.fieldData)
            })
            this.listItems = this.claims
            this.filteredListItems = this.claims

        }catch(e){
            console.log(e)
        }

        this.isLoading = false     
    },

    // startSort: function(key){
    //     console.log(key)
    //     if (this.sortKey == key){
    //         if (this.sortDirection == 0){
    //             key = '-' + key
    //             this.sortDirection = 1
    //         }else{
    //             this.sortDirection = 0
    //         }
    //     }else{
    //         this.sortKey = key
    //         this.sortDirection = 0
    //     }
    //     this.filteredListItems = this.filteredListItems.sort(this.dynamicSort(key))
    // },

    fmSort: function(field){
        this.sortField = field
        var sortOrder = this.sortOrder == 'descend' ? "ascend" : "descend";
        this.sortOrder = sortOrder
        this.getClaims()
    },

    // dynamicSort: function(property) {
        
    //     var sortOrder = 1;
    //     if(property[0] === "-") {
    //         sortOrder = -1;
    //         property = property.substr(1);
    //     }
    //     return function (a,b) {
    //         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //         return result * sortOrder;
    //     }
    // },

    currency: function(price){
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price)
    },

    filterByDate: function() {
        // console.log(dateFrom.value)
        // console.log(dateTo.value)

        if (this.dateTo == '' && this.dateFrom == ''){
            this.filteredListItems = this.listItems
            return
        }

        if (this.dateFrom != '' && this.dateTo != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.claimDate) >= Date.parse(this.dateFrom) && Date.parse(item.claimDate) <= (Date.parse(this.dateTo) + (60*60*24*1000)))
        }else if (this.dateFrom != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.claimDate) >= Date.parse(this.dateFrom))
        }else if (this.dateTo != ''){
            this.filteredListItems = this.listItems.filter(item => Date.parse(item.claimDate) <= (Date.parse(this.dateTo) + (60*60*24*1000)))
        }
        // console.log(filteredListItems.value)
    }
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px;
  border-radius: 6px;

  top: -5px;
  right: 105%;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
