import MyProfile from './MyProfile.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/MyProfile',
      name: 'My Profile',
      component: MyProfile,
      meta: { module: 'profile'}
    }
];