import UpdatePaymentMethod from './UpdatePaymentMethod.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement
import UpdatePaymentLayout from '@/layouts/UpdatePaymentLayout.vue'

export const route: Route[] = [
    {
      path: '/update-payment-method/:customerId/:context',
      name: 'Update Payment Method',
      component: UpdatePaymentMethod,
      meta: { layout: UpdatePaymentLayout },
      props: true,
    }
];