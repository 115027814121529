import axios from 'axios';


export default {
    runScript: function(script: string, parameters: string) {
        const postData = {
            fieldData : {
            },
            script : script,
            'script.param' : parameters
        }
        return axios.post(`proxy.php?/layouts/WEB_LOG/records`, postData)
            .then((response)=>{
                console.log(response.data)
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },
    performFind: function(data: string, layout: string, sortValue = false, scriptNamePre = false, scriptPreParam = false ) {
        const query = {
            query: data,
            limit: 1000,
            ...(sortValue && { sort: sortValue }),
            ...(scriptNamePre && { 'script.prerequest': scriptNamePre }),
            ...(scriptPreParam && { 'script.prerequest.param': scriptPreParam }),
        }
        return axios.post(`proxy.php?/layouts/` + layout + `/_find`, query)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },
    downloadDocument: function(documentID: string){
        return axios.post(`downloadDocument.php`, documentID)
        .then((response) => {
            // success callback
            return response.data
        })
        .catch( (e) => {
            if(e.response.data.messages[0].message){
                //Return FM Error Response
                return e.response.data
            }
            else{
                //Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
    },
}