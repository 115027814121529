import Login from './Login.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement
import AuthLayout from '@/layouts/AuthLayout.vue'

export const route: Route[] = [
    {
      path: '/',
      name: 'Login',
      component: Login,
      meta: { layout: AuthLayout },
      props: true
    },
];