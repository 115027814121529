<template>
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-db-background bg-cover">
        <div class="w-3/5 m-auto xl:w-2/5">
            <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10 md:mx-auto lg:w-3/4 w-full">
                <h1 class="text-3xl font-bold">Update Payment Method</h1>
                <div>
                    <form id="payment-form">
                        <div id="card-container"></div>
                        <button class="bg-capLightPurple hover:bg-capPurple text-white font-bold py-2 px-4 rounded-full mt-4 justify-center" id="card-button" type="button">Update Payment Method</button>
                    </form>
                </div>
            </div>
        </div>
        <!-- <div v-show="isLoading" class=" w-3/5 m-auto xl:w-2/5">
            <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10 md:mx-auto lg:w-3/4 w-full">
                    <tw-loading></tw-loading>
            </div>
        </div> -->
    </div>
</template>


<script setup>
    // #region IMPORT STATEMENTS

    import { ref, defineProps } from 'vue'
    import SharedAPI from '@/api/SharedAPI'

    // #endregion 


    // #region VARIABLES

    const appID = 'sandbox-sq0idb-Yo0dojTcw4V80BwRQYYomg'
    const locationID = 'L0EHMEHTHGJ1K'

    const isLoading = ref(true)

    const props = defineProps([
        'customerId',
        'context'
    ])

    // #endregion


    // #region UPDATE PAYMENT METHOD FUNCTION

    async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
                tokenResult.errors
            )}`;
            }
            throw new Error(errorMessage);
        }
    }

    async function initializeCard(payments) {
        const newCard = await payments.card();
        await newCard.attach('#card-container'); 
        isLoading.value = false
        return newCard; 
    }

    document.addEventListener('DOMContentLoaded', async function () {
        isLoading.value = true
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }
        let payments = window.Square.payments(appID, locationID);
        let card
        try {
            card = await initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }

        // Step 5.2: create card payment
        async function handleStoreCardMethodSubmission(event, paymentMethod) {
            event.preventDefault();

            try {
                // disable the submit button as we await tokenization and make a
                // payment request.
                cardButton.disabled = true;
                const token = await tokenize(paymentMethod);
                // console.log(token)

                //Call FM script to update customer payment method using new card nonce token
                const params = {
                    customerId: props.customerId,
                    cardNonce: token,
                    context: props.context
                }

                const res = await SharedAPI.runScript('Square - Update Card Information', JSON.stringify(params))
                console.log(res)
            } catch (e) {
                cardButton.disabled = false;
                console.error(e.message);
            }
            cardButton.disabled = false;
        }

        const cardButton = document.getElementById(
        'card-button'
        );
        cardButton.addEventListener('click', async function (event) {

            // Add the `handleStoreCardMethodSubmission` method.
            handleStoreCardMethodSubmission(event, card);
            //await handlePaymentMethodSubmission(event, card);
        });
    });

</script>

<style scoped>
    #card-container {
        margin-top: 45px;
        /* this height depends on the size of the container element */
        /* We transition from a single row to double row at 485px */
        /* Settting this min-height minimizes the impact of the card form loading */
        min-height: 90px;
    }

    @media screen and (max-width: 500px) {
        #card-container {
            min-height: 140px;
        }
    }
</style>

