import { createRouter, createWebHistory } from 'vue-router'
import { Route } from '@/types'; // must be imported since file contains an export statement
import store from '@/store/index.ts'
// import { useStore } from 'vuex' 

// function loadLoginRoutes() {
//     const loginRoutes = require('/layouts/login/routes.ts');
//     return loginRoutes.keys().map(loginRoutes).map((m: any) => m.route);
// }

// Import all of the resource routes files
function loadRoutes() {
    const context = require.context('@/modules', true, /routes.ts$/i)
    return context.keys()
        .map(context) // import module
        .map((m: any) => m.route) // get `default` export from each resolved module
}

// function loadLoginRoutes() {
//     const context = require.context('@/layouts/login', true, /routes.ts$/i)
//     return context.keys()
//         .map(context) // import module
//         .map((m: any) => m.route) // get `default` export from each resolved module
// }

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes();
// const loginRoutes = loadLoginRoutes();

// loginRoutes.forEach((loginRoute) => {
//     loginRoute.forEach((route: Route) => {
//         routes.push(route)
//     })
// });

resourceRoutes.forEach((moduleRoutes) => {
    moduleRoutes.forEach((route: Route) => {
        routes.push(route)
    })
});

// Create the router instance and pass the `routes` option
export const router = createRouter({
    history: createWebHistory(), // use history mode
    routes // short for `routes: routes`
})

router.beforeEach(async (to: any, from: any, next: any) => {
    // const store = useStore()
    console.log(store.state.user.loggedIn)
    const loggedIn = store.state.user.loggedIn
    if (to.name !== 'Login' && to.name !== 'Update Payment Method' && to.name !== 'PasswordReset' && !loggedIn) next({ name: 'Login', query: { redirect: to.fullPath } })
    else next()
});