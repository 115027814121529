import Charges from './Charges.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/charges',
      name: 'Charges',
      component: Charges,
      meta: { module: 'Charges' }
    }
];