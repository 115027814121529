
    import { defineComponent, computed } from 'vue';
    import { useRoute } from 'vue-router'
    import ApplicationShellVerticalExample from './layouts/ApplicationShellVerticalExample.vue'

    //test
    export default defineComponent({
        name: 'App',
        components:{
            ApplicationShellVerticalExample,
        },
        setup(){
            const isLoaded = true
            
            const route = useRoute()
            // console.log(route.meta)
            const layout = computed(() => {
                return route.meta.layout || 'ApplicationShellVerticalExample'
            });
            
            return {isLoaded, layout}
        },
    })
