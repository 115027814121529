/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store/index'
import { router } from './router/index'
import './index.css'
import axios from 'axios'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";


// Import components to use globally
import Loading from '@/components/Loading/Loading.vue'


// import icons from Tailwind's Hero Icons
// EX: import { HomeIcon } from '@heroicons/vue/solid'
import { ClockIcon, CurrencyDollarIcon, ExclamationCircleIcon, ArrowSmDownIcon, ArrowSmUpIcon, XIcon } from '@heroicons/vue/outline' 


const app = createApp(App)

// Register icons globally
// Ex: app.component('HomeIcon', HomeIcon)
// In another component use, <HomeIcon/> directly in the template
app.component("ClockIcon", ClockIcon)
app.component("CurrencyDollarIcon", CurrencyDollarIcon)
app.component("ExclamationCircleIcon", ExclamationCircleIcon)
app.component("ArrowSmDownIcon", ArrowSmDownIcon)
app.component("ArrowSmUpIcon", ArrowSmUpIcon)
app.component("XIcon", XIcon)
 
// Register components globally
app.component("tw-loading", Loading)

app.use(Toast)

app.use(store)
app.use(router)
router.isReady().then(() => app.mount('#app'))


//---------AXIOS Setup------------//
// if( localStorage.sessionId == undefined){
//   localStorage.sessionId = Math.random().toString(36).substring(2);
// }
// var sessionId = localStorage.sessionId;
// axios.defaults.baseURL = "https://parish-client-portal-dbservices.c9users.io/api/v1/";
// axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + '/api/v1/';
// if(window.location.hostname == 'localhost'){
//     axios.defaults.baseURL = 'http://localhost:8888/auriel-forms/api/v1/'
//   }
//   else{
    // axios.defaults.baseURL = 'https://446u579yka.execute-api.us-east-2.amazonaws.com/default/fmDataAPI';
//   }
// axios.defaults.headers.common['x-api-key'] = 'iDAnZ6CTJU9GRJ9M5NOdD4m4nX1aipkZ98qTK5jt';
// axios.defaults.headers.common['Content-Type'] = 'application/json';
if (window.location.hostname == 'localhost'){
    axios.defaults.baseURL = 'http://localhost:8888/complete-appliance-protection-portal/api/v1/'
}else{
    axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + '/api/v1/';
}