<template>
        <!-- <AuthorizeNetForm :isLoading="isLoading" :show-modal="showModal" @close="showModal = false"></AuthorizeNetForm> -->

        <!-- <div class="pb-12 flex justify-between">
            <h1 class="font-bold text-3xl">Contract Details</h1>
            <button v-if="!isPDFLoading && !isLoading" class="bg-capLightPurple hover:bg-capPurple text-white font-bold py-2 px-4 rounded-full ml-auto inline" @click="generatePDF">
                Generate PDF
            </button>
            <div v-else-if="isPDFLoading" class="flex">
                <span class="animate-pulse italic mr-8">Generating PDF...</span>
                <tw-loading/>
            </div>
        </div>  -->


         <div class="bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700 mb-8">
            <div class="px-4 py-5 sm:p-6 font-bold flex flex-row">
            <!-- Content goes here -->
                <div v-if="!isLoading" class="flex-1 space-y-2">
                    <div v-if="contract.contractNumberDisplay != ''">
                        <h2 class="text-gray-500 ">Contract Number</h2>
                        <h2>{{contract.contractNumberDisplay}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Contract Number</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.activeDate != ''">
                        <h2 class="text-gray-500 ">Active Date</h2>
                        <h2>{{contract.activeDate}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Active Date</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.expirationDate != ''">
                        <h2 class="text-gray-500 ">Expiration Date</h2>
                        <h2>{{contract.expirationDate}}</h2>
                    </div>
                    <div v-if="contract.propertyManagementCompanyName != ''">
                        <h2 class="text-gray-500 ">Property Manager</h2>
                        <h2>{{contract.propertyManagementCompanyName}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Property Manager</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.zctWebPMEmail != ''">
                        <h2 class="text-gray-500 ">Property Manager Email</h2>
                        <h2>{{contract.zctWebPMEmail}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Property Manager Email</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.zctWebPMPhone != ''">
                        <h2 class="text-gray-500 ">Property Manager Phone</h2>
                        <h2>{{contract.zctWebPMPhone}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Property Manager Phone</h2>
                        <h2>—</h2>
                    </div>
                </div>
                <div v-if="!isLoading" class="flex-1 pl-2 space-y-2">
                    <div v-if="contract.status != ''">
                        <h2 class="text-gray-500 ">Status</h2>
                        <h2>{{contract.status}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Status</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.zctWebPhysicalAddressLabel != ''">
                        <h2 class="text-gray-500 ">Physical Address</h2>
                        <h2>{{contract.zctWebPhysicalAddressLabel}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Physical Address</h2>
                        <h2>—</h2>
                    </div>
                    <div v-if="contract.zctWebMailingAddressLabel != ''">
                        <h2 class="text-gray-500 ">Mailing Address</h2>
                        <h2>{{contract.zctWebMailingAddressLabel}}</h2>
                    </div>
                    <div v-else>
                        <h2 class="text-gray-500 ">Mailing Address</h2>
                        <h2>—</h2>
                    </div>
                </div>
                <div v-if="isLoading" class="text-center w-full p-4">
                    <tw-loading></tw-loading>
                </div>
            </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg border-t-8 border-gray-700 mb-8">
            <div class="px-4 py-5 sm:p-6 font-bold">
                <div class="mb-6">
                    <h2 class="font-bold text-lg text-gray-700">Plan: {{contract.planName}}</h2>
                </div>
                <p v-html="contract.zctWebPaymentHTML"></p>
                <!-- <table class="w-full">
                    <tr class="flex">
                        <th v-for="(header, index) in headers" :key="index" class="flex-1 mr-auto text-left">
                            <span @click="startSort(header.var)" class="cursor-pointer flex pl-2">
                                {{header.name}} 
                                <ArrowSmDownIcon v-show="header.var == sortKey && sortDirection == 1" class="h-6 w-6 pl-2"/>
                                <ArrowSmUpIcon v-show="header.var == sortKey && sortDirection == 0" class="h-6 w-6 pl-2"/>

                            </span>
                        </th>
                    </tr>
                    <div class="border shadow-sm block h-84 overflow-y-auto">
                        <tr v-show="!isLoading" v-for="item in filteredListItems" :key="item" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 p-1  pl-2">{{item.productName || '—'}}</td>
                            <td class="flex-1 p-1 pl-2">{{currency(item.unitPrice) || '—'}}</td>
                        </tr>
                        <tr v-show="!isLoading && listItems.length < 14" v-for="index in fillRemainingRows" :key="index" class="flex flex-row even:bg-purple-100">
                            <td class="flex-1 h-7 pl-2"></td>
                            <td class="flex-1 pl-2"></td>
                        </tr>
                    </div>
                    <tr v-show="isLoading">
                        <td class="text-center w-full p-4" :colspan="headers.length + 1">
                            <tw-loading></tw-loading>
                        </td>
                    </tr>
                </table> -->
            </div>
        </div> 
        <div>

        </div>
</template>

<script>
    import SharedAPI from '@/api/SharedAPI'
    import { mapState } from 'vuex'
    // import AuthorizeNetForm from '@/components/PaymentForms/AuthorizeNetForm.vue'
    // import { useToast } from 'vue-toastification'

export default {
  props: ['id'],
  data() {
    return {
        isLoading: true,
        contractLineItems: [],
        listItems: [],
        filteredListItems: [],
        contract: {},
        headers: [
            {name: 'Add-on Name', var: 'productName'},
            {name: 'Price', var: 'unitPrice'},
        ],
        sortKey: 'contractNum',
        sortDirection: 0,
    };
  },

  mounted() {
    this.getContractDetails()
  },

  computed: {
    ...mapState([
      'user'
    ]),

    fillRemainingRows(){
        var fillRows = 14 - this.listItems.length 
        if (fillRows > 0){
            return fillRows
        }else{
            return 0
        }
    }
  },

  methods: {
    getContractDetails: async function(){
        this.isLoading = true

        try{
            //Get Contract
            var params = [{
                '__kptID' : this.id,
            }]
            var res = await SharedAPI.performFind(params, 'contract_detail', false, 'set_globals', this.id) 
            console.log(res)           
            this.contract = res.response.data[0].fieldData

            //Get Line Items
            // var paramsLineItem = {
            //     '_kftContractID' : this.id,
            // }
            // var resLineItems = await SharedAPI.performFind(paramsLineItem, 'contract_line_item') 
            // console.log(resLineItems)
            // resLineItems.response.data.forEach(lineItem => {
            //     this.contractLineItems.push(lineItem.fieldData)
            // })
            
            // this.listItems = this.contractLineItems
            // this.filteredListItems = this.contractLineItems

        }catch(e){
            console.log(e)
        }

        this.isLoading = false     
    },

    startSort: function(key){
        if (this.sortKey == key){
            if (this.sortDirection == 0){
                key = '-' + key
                this.sortDirection = 1
            }else{
                this.sortDirection = 0
            }
        }else{
            this.sortKey = key
            this.sortDirection = 0
        }
        this.filteredListItems = this.filteredListItems.sort(this.dynamicSort(key))
    },

    dynamicSort: function(property) {
        
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },

    currency: function(price){
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price)
    }
  }
}


</script>

<style scoped>
    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 98;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;

        width: 100%;
        max-width: 400px;
        background-color: #FFF;
        border-radius: 16px;

        padding: 25px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-enter-active, 
    .slide-leave-active {
        transition: transform 0.5s;
    }

    .slide-enter-from, 
    .slide-leave-to {
        transform: translateY(-50%) translateX(100vw);
    }
</style>
