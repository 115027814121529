import { CurrencyDollarIcon } from '@heroicons/vue/outline'
import { Nav } from '@/types' // must be imported since file contains an export statement

export const nav: Nav = { 
    name: 'Payments', 
    to: {name: 'Charges'}, 
    icon: CurrencyDollarIcon, 
    position: 2,
    show: true,
    module: 'Charges'
}