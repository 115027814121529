import PasswordChange from './ChangePassword.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import { Route } from '@/types'; // must be imported since file contains an export statement

export const route: Route[] = [
    {
      path: '/password-change',
      name: 'PasswordChange',
      component: PasswordChange,
      meta: { module: 'ChangePassword', layout: AuthLayout },
      
    },
];